<template>
  <div>
    <a-card title="历史库存报表">
      <a-row :gutter="16">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="产品编号/名称" allowClear @search="search" />
        </a-col>
        <!--<a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%;" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">{{item.name}}
            </a-select-option>
          </a-select>
        </a-col>-->

        <a-col :span="8">
          <a-form-model-item label="查询日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-date-picker v-model="searchForm.inventory_date" @change="onChangePicker" style="width: 100%;" />
          </a-form-model-item>
        </a-col>

        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-select v-model="searchForm.has_stock" placeholder="库存状态" allowClear style="width: 100%;" @change="search">
            <a-select-option  :value="true">有库存</a-select-option>
            <a-select-option  :value="false">无库存</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-select v-model="searchForm.goods_category" placeholder="商品种类" allowClear style="width: 100%;" @change="search">
            <a-select-option  :value="1">常温库</a-select-option>
            <a-select-option  :value="2">冷藏库</a-select-option>
            <a-select-option  :value="3">冷冻库</a-select-option>
            <a-select-option  :value="4">浙一专供</a-select-option>
          </a-select>
        </a-col>
        <!--
        <a-col :span="24" style="width: 300px; margin-bottom: 12px;">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col> -->



        <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-range-picker @change="onChangePicker" />
        </a-col> -->
      </a-row>
      <!--
      <a-descriptions :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }" style="text-align: center;">
        <a-descriptions-item label="库存商品数"><span class="total">{{totalCount}}</span></a-descriptions-item>
        <a-descriptions-item label="库存总数量"><span class="total">{{totalQuantity}}</span></a-descriptions-item>
        <a-descriptions-item label="库存总金额"><span class="total">{{totalAmount}}</span></a-descriptions-item>
      </a-descriptions>-->

      <a-row style="margin-top: 12px;">
        <a-table size="small"
          rowKey="id"
          :columns="columns"
          :dataSource="items" 
          :loading="loading"
          :pagination="pagination"
          @change="tableChange">
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { warehousesOption } from '@/api/option'
  import { inventoryDateReportList, inventoryStatistics } from '@/api/report'
  import { exportExcel } from '@/utils/excel'
  import { goodsInventoryExport } from '@/api/export'
  import NP from 'number-precision'
  import moment from 'moment'

  export default {
    name: 'InventoryReport',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '仓库',
            dataIndex: 'warehouse_name',
          },
          {
            title: '日期',
            dataIndex: 'inventory_date',
          },
          {
            title: '产品名称',
            dataIndex: 'goods_name',
          },
          {
            title: '产品编号',
            dataIndex: 'goods_number',
          },
          {
            title: '成本价',
            dataIndex: 'cost_price',
          },
          {
            title: '库存总数',
            dataIndex: 'total_quantity',
          },
          {
            title: '单位',
            dataIndex: 'unit_name',
            width: 80
          },
          {
            title: '总金额',
            dataIndex: 'total_amount',
            customRender: (value, item, index) => {
              return (item.total_quantity * item.cost_price).toFixed(2)
             }
            },
          {
            title: '库存状态',
            dataIndex: 'has_stock',
            customRender: (value, item, index) => {
              return item.has_stock ? '有' : '无'
            },
          },
          // {
          //   title: '操作',
          //   dataIndex: 'action',
          //   scopedSlots: { customRender: 'action' },
          //   width: 147
          // },
        ],
        searchForm: { page: 1, page_size: 20 },
        pagination: { current: 1, total: 0, pageSize: 20 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
        warehouseItems: 0,
        total: {
          quantity: 0,
          cost: 0,
          amount: 0,
        },
      };
    },
    computed: {
      totalCount() {
        return this.total.total_count ? this.total.total_count : 0
      },
      totalQuantity() {
        return this.total.total_quantity ? this.total.total_quantity : 0
      },
      totalAmount() {
        return this.total.total_amount ? NP.round(this.total.total_amount, 2) : 0
      },
    },
    methods: {
      initialize() {
        warehousesOption({ page_size: 99999 }).then(resp => {
          this.warehouseItems = resp.results;
        });
        this.list();
      },
      list() {
        this.loading = true;
        console.log("this.searchForm", this.searchForm)
        inventoryStatistics().then(resp => {
          this.total = resp;
        })
        inventoryDateReportList(this.searchForm).then(data => {
          console.log("this.searchForm", this.searchForm)
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        console.log(pagination)
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      onChangePicker(date, dateString) {
        console.log("date:",date)

        this.searchForm.inventory_date = date ? date.format('YYYY-MM-DD') : undefined;
        this.search();
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },

      exportExcel() {
        goodsInventoryExport(this.searchForm).then(resp => {
          exportExcel(resp.data, '产品库存列表');
        }).catch(err => {
          this.$message.error(err.response.data.error);
        });
      },

    },
    mounted() {
      this.initialize();
    },
  }
</script>